<template>
  <div class="main-div">
    <div class="birthday-main-div" v-if="model && model.item">
      <h1 class="birth-user-name">
        {{ model.item.description }}
      </h1>
      <img :src="fileUrl" alt="" width="325" height="325" />
    </div>

  </div>
</template>

<script>
import store from "../store/index";
export default {
  name: "birthday",
  props: ["data"],
  data() {
    return {
      destroyed: false,
      fileUrl: '/images/bg/birthday.png'
    };
  },
  created() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      if (this.model.item.image) {
        this.fileUrl = process.env.VUE_APP_BASE_CDN_URL + '/' + this.model.item.image.fileName;
      }
      else {
        this.fileUrl = process.env.VUE_APP_BASE_CDN_URL + '/' + store.state.logo;
      }
      setTimeout(() => {
        store.dispatch('setNextPlaylist')
        return;
      }, (this.model.item.displaySecond || 30) * 1000)
    }
  },
  computed: {
    model() {
      return this.$store.state.birthday;
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
.main-div {
  position: fixed;
  width: 100%;
  height: 100%;
}

.birthday-main-div {
  background-image: url('/images/bg/birthday.png');
  background-position: center;
  background-size: 100% 100%;
}

.birthday-main-div img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 71px;
  border: 15px solid white;
  border-radius: 10px;
}

.birthday-main-div {
  width: 100%;
  height: 100%;
  position: relative;
}

.birthday-main-div h1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  bottom: 460px;
  font-size: 70px;
  text-transform: uppercase;
}
</style>
